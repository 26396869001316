.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo {
  max-width: 150px;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-right: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Set the global margin and padding to zero to prevent unwanted space */
html,
body {
  margin: 0;
  padding: 0;
  /* Apply your background color to the whole app */
}

.container {
  max-width: 700px;
  margin: 0 auto;
  /* Center the container */
}

.header {
  display: flex;
  align-items: center;
  /* Align vertically */
  justify-content: space-between;
  /* Spreads out the children; use flex-start to group them to the left */
  padding: 0 20px;
  /* Add padding on the sides */
  /* Rest of your styles */
}

.footer {
  padding: 2rem;
  /* Or whatever padding you want */
  /* position: -webkit-sticky; For Safari */
  /* position: sticky; */
  bottom: 0;
  /* width: 100%; */
  color: #fff;
  font-size: small;
}

/* Adjust the main content padding or margin to prevent overlap with the fixed footer */
.main-content {
  padding-bottom: 5rem;
  /* Adjust so it's equal or greater than the footer's height */
}

.modal-content-scroll {
  max-height: 70vh;
  overflow-y: auto;
}

.deposit-entry {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.deposit-entry h6 {
  margin-bottom: 10px;
}

.pubkey {
  word-break: break-all;
}

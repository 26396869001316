body {
    background-color: #322537; /* Soft gray background */
}

.container {
    background: #ffffff; /* White background for the content area */
    padding: 20px;
    border-radius: 8px; /* Rounded corners for the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.wallet-connected {
    color: #495057; /* Dark gray that's easy on the eyes */
    margin-bottom: 1rem;
    font-size: 1rem;
    align-content: baseline;
    justify-content: right;
}

.btn-connect-wallet {
    background-color: #68c3a3; /* Pastel teal for connect wallet */
    border: none;
    color: #faf8f8; 
    font-weight: 600;
}
  
.btn-connect-wallet:hover {
    background-color: #57b492; /* Slightly darker teal on hover */
}

.btn-disconnect-wallet {
    padding: 0.25rem 0.5rem; /* Adjust padding to make the button smaller */
    font-size: 0.8rem; /* Smaller font size for the icon */
    line-height: 1; /* Normal line height for the button content */
    border-radius: 0.2rem; /* Smaller border-radius for a less rounded button */
}

.btn-stake {
    background-color: #e6a4b4; /* Pastel coral for the stake action */
    border: none;
    color: #333; /* Dark color for text to ensure readability */
    font-weight: 500; /* Bolder text */
}
  
.btn-stake:hover {
    background-color: #d595a5; /* Slightly darker coral on hover */
}

.btn-stake:disabled {
    background-color: #c0c0c0; /* A neutral gray color for disabled state */
    border-color: #c0c0c0; /* Consistent border color for disabled state */
    color: #6c757d; /* A darker gray to provide contrast for the text */
}

.data-badges-container {
    padding: 1rem;
    background-color: #f8f9fa; /* Light gray to distinguish the section */
    border-radius: 0.15rem;
    margin-bottom: 1rem;
}

.data-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 0.20rem; /* Adjust gap for both horizontal and vertical spacing */
}

.data-badges .badge-wrap {
    display: inline-block; /* Display badges inline with wrapping */
    margin: 0.15rem 0.15rem 0.15rem 0; /* Reduced margins for tighter spacing */
  }
  
.data-badges .badge {
    white-space: normal; /* Allow text to wrap */
    word-break: break-all; /* Ensure long text doesn't overflow */
    padding: 0.25rem 0.5rem; /* Reduced padding inside the badges */
    font-size: 0.8rem; /* Smaller font for long strings */
    color: #fff; /* White text for all badges */
    margin-bottom: 0.1rem; /* Space below each badge */
    justify-self: left;
    font-family: 'Courier New', Courier, monospace;
    background-color: #524763; /* A slightly lighter shade of your background for the instructional box */
}

/* Color palette */
.bg-primary { background-color: #524763; } /* Dark blue */
.bg-success { background-color: #27ae60; } /* Vibrant green */
.bg-info { background-color: #3498db; } /* Bright blue */
.bg-warning { background-color: #f39c12; } /* Deep orange */

.data-badges-container .form-label {
    color: #495057; /* Dark gray for text */
}

.deposits-label {
    color: #495057; /* A neutral color for the label */
    margin-bottom: 1rem; /* Adds space below the element */
    /* ... other styles ... */
  }
  
.deposits-number-badge {
    color: #000; /* White text for contrast */
    font-weight: bold; /* Bold font */
    padding: 0.25rem 0.5rem; /* Padding inside the badge */
    border-radius: 10px; /* Rounded corners for the badge */
    font-size: 1rem; /* Larger font size for emphasis */
    margin-left: 0.5rem; /* Space from the label */
    vertical-align: middle; /* Aligns badge with the text */
    animation: popIn 1.2s ease;
    font-family: 'Courier New', Courier, monospace;
}

@keyframes popIn {
0% { transform: scale(0.8); opacity: 0.1; }
60% { transform: scale(1.1); }
100% { transform: scale(1); opacity: 1; }
}

.file-upload-instructions p {
    color: #fff; /* White text or any color that stands out on your dark background */
    background-color: #524763; /* A slightly lighter shade of your background for the instructional box */
    padding: 10px;
    border-radius: 8px; /* Optional: rounded corners for the instructional box */
    margin-top: 20px; /* Spacing from the header or any other elements */
}